<script lang="ts" setup>
  import RouletteSlider from '~/components/sliders/roulette/RouletteSlider.vue';
  import { getNoveltyMovies } from '~/services/modules/movies.service';
  import { useQueryClient } from '@tanstack/vue-query';
  import { maxMovieCardsCountAtSlider } from '~/consts/slider';

  const queryClient = useQueryClient();
  queryClient.removeQueries({ queryKey: ['novelty'], stale: true });

  const { data, suspense, isFetching } = getNoveltyMovies({ params: { novelty: 0, category: 'film' } });

  onServerPrefetch(async () => await suspense());
</script>

<template>
  <v-wrapper
    section
    class="movies full-width"
    title="Фильмы"
    remove-padding
    :link="{ name: 'Смотреть все', route: { name: 'novelty-films' } }"
  >
    <roulette-slider
      appendClass="movies__slider content-right"
      :items="data?.slice(0, maxMovieCardsCountAtSlider) ?? []"
      :is-fetching="isFetching"
      skeleton
    />
  </v-wrapper>
</template>

<style lang="scss" scoped>
  .movies {
    padding: 56px 0 0;
    //min-width: 0;
    //&__slider {
    //  min-width: 0;
    //}
    @media (max-width: $retina) {
      padding: 24px 0;
    }
  }
</style>
